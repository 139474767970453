.ljd_notification_container {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 100000;
  pointer-events: none;
  .ljd_toast {
    background-color: #283130;
    border-radius: 0;
    min-width: 250px;
    padding: 16px 12px;
    color: #fff;
  }
}

@media (max-width: 575.98px) {
  .ljd_notification_container {
    top: auto;
    bottom: 20px;
    left: 20px;
  }
}
